.progress-bar {
  position: relative;
  display: block;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
}

.progress-bar span {
  position: absolute;
  top: 0;
  width: var(--progress-width);
  height: 100%;
  border-radius: 10px;
  background-color: #4568dc;
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
  transition: width 0.5s ease-in-out;
}

.progress-bar .progress-bar-text {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 30%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

.progress-bar div.progress-bar-text span {
  color: black;
  margin-right: 5px;
  text-align: center;
}
