 .petition {
    position: relative;
    padding: 10px;
    width: 500px;
    height: 360px;
    margin: 10px;
    flex: 0 0 500px;
    border: 2px solid lightgray;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
  }
  
   .petition .upper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
   .petition-img img {
    border: 2px solid #ddd;
    border-radius: 2px;
    width: 200px;
    height: 160px;
  }
  
   .petition-info-top {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }
  
   .petition-info-top p {
    color: #666;
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
   .petition-info-top span {
    color: #f8ab46;
    font-weight: bold;
  }
  
   .petition-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  
   .petition-info h1 {
    font-size: 24px;
    margin: 0;
    color: #333;
  }
  
   .petition-info p {
    color: #666;
    margin-top: 5px;
    font-size: 16px;
    line-height: 1.5;
  }
  
   .petition-info span {
    color: #f8ab46;
    font-weight: bold;
  }
  
   .petition .btn-details {
    margin-top: auto;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    background-image: linear-gradient(45deg, rgba(143, 152, 185, 0.7), rgba(83, 83, 83, 0.6));
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    color: #fff;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
  }
  
   .petition .btn-details:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }