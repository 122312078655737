body {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/illustration-world-map);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 0 7%;
  color: rgb(0, 0, 0);
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-top: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content h1 {
  font-size: 60px;
  margin-bottom: 15px;
}

.content p {
  line-height: 22px;
  font-size: 20px;
}

.content .create-petition {
  display: inline-block;
  margin-top: 30px;
  background: #ff960b;
  color: #fff;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 10px;
}

.petition-list {
  display: flex;
  flex-direction: column;
}

/* .petition-list h3 {
  font-size: 30px;
  margin-bottom: 15px;
} */

.finished-petitions {
  display: flex;
  flex-direction: row;
  background-color: rgba(158, 185, 179, 0.13);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.latest-petitions {
  display: flex;
  flex-direction: column;
  background-color: rgba(158, 185, 179, 0.13);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}






footer {
  margin-top: 80px;
  width: 100%;
  color: rgb(0, 0, 0);
  text-align: center;
}