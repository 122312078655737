#comments-section {
    background-color: rgba(158, 185, 179, 0.13);
    border-radius: 1px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    max-width: 800px;
    padding: 30px;
  }
  
  #comments-section h3 {
    font-size: 28px;
    margin-bottom: 50px;
    text-align: center;
  }
  
  #comments-section ul {
    list-style: none;
    padding: 0;
  }
  
  .comment {
    margin-bottom: 20px;
  }
  
  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .comment-username {
    font-weight: bold;
  }
  
  .comment-body {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  #comments-section form {
    margin-top: 20px;
  }
  
  #comments-section label {
    font-weight: bold;
  }

  hr {
    border: 1px dotted lightgray
  }
  
  #comments-section textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid lightgray;
    border-radius: 1px;
    background-color: #f2f2f2;
    resize: none;
  }
  

  
  #comments-section .btn-submit-comment:hover {
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  }

  br{
    line-height: 20px;
  }
  