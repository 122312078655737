main {
    padding: 20px;
  }
  
  #details-info {
    background-color: rgba(158, 185, 179, 0.13);
    border-radius: 1px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    max-width: 800px;
    padding: 30px;
  }
  
  #details-info h1 {
    font-size: 28px;
    margin-top: 0;
    text-align: center;
  }
  
  #details-info .petition-image {
    height: calc(100% - 30px);
    overflow: hidden;
}

#details-info .petition-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
  
  #details-info .petition-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  
  #details-info .petition-category {
    font-style: italic;
    text-align: center;
    opacity: 0.6;
  }
  
  #details-info .petition-summary {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  #details-info .petition-description {
    font-size: 16px;
    margin: 20px 0;
  }
  
  #details-info .petition-author {
    font-style: italic;
    text-align: center;
    margin-top: 30px;
  }
  
  #details-info .buttons{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }

  .btn {
    margin-top: auto;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    background-image: linear-gradient(45deg, rgba(143, 152, 185, 0.7), rgba(83, 83, 83, 0.6));
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    color: #fff;
    text-decoration: none;
    bottom: 10px;
  }
  

  
  .btn:hover {
    /* transform: translateY(-3px); */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  
  
  
  
  
  
  
  