* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    color:rgb(0, 0, 0)

}







  #header {
    font-weight: bold;
    display: inline-block;
  }

  .menu {
    display: none;
    margin-left: auto;
  }
  
  .menu button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .menu button:focus {
    outline: none;
  }
  
  .menu svg {
    display: block;
    width: 24px;
    height: 24px;
  }
  
  nav {
    display: flex;
    font-weight: bold;
    width: 100%;
    height: 70px;
    padding: 10px 0;
    align-items: center;
    
    margin-bottom: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: white;
  }
  
  .logo {
    width: 50px;
    cursor: pointer;
    vertical-align: middle;
  }
  
  nav ul {
    margin-left: 20px;
    display: flex;
    list-style-type: none;
  }
  
  nav ul li {
    display: inline-block;
    margin: auto 20px;
  }

  nav ul li.search button {
    font-size: inherit;
    font-weight: inherit;
    text-decoration:underline;
  }
  nav ul li.search button:hover {
    text-decoration: none;
  }
  
  nav ul li a {
      font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
  
  nav ul li a:hover {
    text-decoration: none;
  }
  
  nav #search {
    margin-left: auto;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    color: rgb(0, 0, 0);
    cursor: pointer;
  }
  

  @media only screen and (max-width: 768px) {
    @media only screen and (max-width: 768px) {
      .menu {
        display: block;
      }
    
      .header.visible nav {
        display: block;
        width: 100%;
      }
    }

    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .logo {
      margin-bottom: 1rem;
    }
  
    nav ul {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  
    nav li {
      margin: 0.5rem 0;
    }
  
    .search button {
      margin-top: 1rem;
    }
  }