main {
    padding-top: 50px;
}

.login-box {
    color: white;
    width: 400px;
    height: auto;
    margin: auto;
    padding-bottom: 20px;
    background-color: rgba(184, 239, 246, 0.304);
    border: 2px solid white;
    border-radius: 20px;
}

.login-box h1 {
    text-align: center;
    padding-top: 15px;
}

h4 {
    margin-top: 10px;
    text-align: center;
}

form {
    width: 300px;
    margin-left: 35px;
}

form label {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
}

form input {
    width: 320px;
    height: 30px;
    padding-left: 7px;
    border: none;
    border: 1px solid gray;
    border-radius: 8px;
    outline: none;
}

input[type="submit"] {
    width: 320px;
    height: 35px;
    margin-top: 20px;
    border: none;
    background-color: #49c1a2;
    color: white;
    font-size: 15px;
}

.login-box p {
    text-align: center;
    /* align-items: center; */
    padding-top: 20px;
    font-size: 15px;

}



.google-sign-in-button {
    display: inline-block;
    padding: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    line-height: 1;
  }
  
  .google-sign-in-button img {
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }