/* .search-results-container {
  margin-top: 10px;
} */

/* .search-results {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.20);
    border-radius: 3px;
    padding: 0;
    margin: 0;
    z-index: 1;
  } */
  .search-results {
    position: absolute;
    top: 140px;
    left: 120px;
    width: 50%;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.20);
    border-radius: 3px;
    padding: 0;
    margin: 0;
    z-index: 10000;
  }
  .search-result {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .result-image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 3px;
  }
  
  .result-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .result-details h2 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
  }
  
  .result-details p {
    font-size: 14px;
    color: #888;
    margin: 0;
  }

  