main {
    padding-top: 50px;
}

#create-container {
    color: white;
    width: 800px;
    height: auto;
    margin: auto;
    background-color: rgba(184, 239, 246, 0.304);
    border: 2px solid white;
    border-radius: 20px;
}

#create-container h1 {
    text-align: center;
    padding-top: 15px;
    /* color: #f5ebdd; */

} 

#create-container h4 {
    margin-top: 10px;
    text-align: center;
    color: #ff960b;
}

#create-container form {
    width: 700px;
    margin-left: 20px;
}

#create-container form label {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
}

#create-container form input {
    width: 650px;
    height: 30px;
    padding-left: 7px;
    border: none;
    border: 1px solid gray;
    border-radius: 8px;
    outline: none;
    background-color: whitesmoke;
}

#create-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#create-container input[type=number] {
    -moz-appearance: textfield;
}

#create-container form textarea {
    width: 650px;
    height: auto;
    padding-left: 7px;
    border: none;
    border: 1px solid gray;
    border-radius: 8px;
    outline: none;
    background-color: whitesmoke;
    resize:vertical;
}

#create-container select {
    outline: 0;
    border: 0;
    box-shadow: none;
    flex: 1;
    padding: 0 1em;
    cursor: pointer;
    height: 20px;
    border-radius: 8px;
    width: 320px;
    color: black;
    background-color: whitesmoke;
}

#create-container input[type="submit"] {
    width: 320px;
    height: 35px;
    margin-top: 20px;
    border: none;
    background-color: #49c1a2;
    color: white;
    font-size: 15px;
    margin-bottom: 30px;
}
#create-container ul {
    display:grid;
}

#create-container div li {
    list-style-type: none;

}

#create-container div li input[type="checkbox"]{
    vertical-align: middle;
}
