main {
    padding-top: 50px;
}

.register-box {
    color: white;
    width: 400px;
    height: auto ;
    margin: auto;
    background-color: rgba(184, 239, 246, 0.304);
    border: 2px solid white;
    border-radius: 20px;
}

.register-box h1 {
    text-align: center;
    padding-top: 15px;
}

.register-box h4 {
    margin-top: 10px;
    text-align: center;
}

.register-box form {
    width: 300px;
    margin-left: 20px;
}

.register-box form label {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
}

.register-box form .gender {
    display: flex;
    margin-top: 10px;
}

.register-box form input {
    width: 320px;
    height: 30px;
    padding-left: 7px;
    border: none;
    border: 1px solid gray;
    border-radius: 8px;
    outline: none;
}

.register-box input[type="submit"] {
    width: 320px;
    height: 35px;
    margin-top: 30px;
    border: none;
    background-color: #49c1a2;
    color: white;
    font-size: 15px;
}

.register-box p {
    text-align: center;
    padding-top: 20px;
    font-size: 15px;

}

.register-box p>a {
    color: #49c1a2;
}

.register-box .error {
    font-size: 15px;
}