main {
    padding-top: 20px;
    margin-top: 80px;
}

.petition-catalog-list h1 {
    text-align: left;
    font-size: 30px;
    margin-bottom: 5px;
}

.petition-catalog-list h1 span {
    color: whitesmoke;
}

.petitions-list {
    display: flex;
    flex-direction: row;
    align-items: normal;
    border-top: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    border-radius: 1px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.20),
        0 2px 10px 0 rgba(0, 0, 0, 0.20);
    background-color: rgba(158, 185, 179, 0.13);
    width: auto;
    height: 400px;
    margin-top: auto;
    overflow-y: scroll;
}

.petitions-list::-webkit-scrollbar {
    width: 20px;
}

.petitions-list::-webkit-scrollbar-track {
    background-color: rgba(228, 223, 223, 0.991);
    border-radius: 2px;
}

.petitions-list::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #c2fff1 0%, #e7e9eb 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 20px;
}





  @media only screen and (max-width: 768px) {
   /* phone */
    .petition-catalog-list h1 {
    font-size: 24px;
    margin-bottom: 10px;
    }
    
    .petitions-list {
    height: auto;
    margin-top: 20px;
    }
    
    .petition-catalog-list .petition {
    width: 100%;
    flex: 0 0 100%;
    margin: 10px 0;
    padding: 20px;
    height: auto;
    }
    
    .petition-catalog-list .petition-img img {
    width: 100%;
    height: auto;
    }
    
    .petition-catalog-list .petition .upper-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    }
    
    .petition-catalog-list .petition-info-top {
    align-items: flex-start;
    text-align: left;
    }
    
    .petition-catalog-list .petition-info-top p {
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.2;
    }
    
    .petition-catalog-list .petition-info {
    padding-bottom: 10px;
    }
    
    .petition-catalog-list .petition-info h1 {
    font-size: 20px;
    margin: 0;
    }
    
    .petition-catalog-list .petition-info p {
    font-size: 14px;
    line-height: 1.2;
    }
    
    .petition-catalog-list .petition .btn-details {
    position: static;
    margin-top: 10px;
    }
    }
    
    @media only screen and (max-width: 1024px) {
    /* tablet */
    .petition-catalog-list h1 {
    font-size: 26px;
    margin-bottom: 10px;
    }
    
    .petition-catalog-list .petition {
    width: 100%;
    flex: 0 0 100%;
    margin: 10px 0;
    padding: 20px;
    height: auto;
    }
    
    .petition-catalog-list .petition-img img {
    width: 100%;
    height: auto;
    }
    
    .petition-catalog-list .petition-info-top {
    text-align: left;
    }
    
    .petition-catalog-list .petition-info-top p {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.5;
    }
    
    .petition-catalog-list .petition-info h1 {
    font-size: 24px;
    margin: 0;
    }
    
    .petition-catalog-list .petition-info p {
    font-size: 16px;
    line-height: 1.5;
    }
    }

    @media (max-width: 767px) {
        .petition-catalog-list .petition .btn-details {
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            outline: none;
            font-size: 16px;
            background-image: linear-gradient(45deg, #8f98b9, #dafac7);
            border-radius: 5px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s, box-shadow 0.2s;
            color: #fff;
            text-decoration: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
      }