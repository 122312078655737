

main {
    padding-top: 50px;
}


.petition-search h1 {
    padding-top: 0px;

    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}

.search-form {
    padding-top: 0px;
    position: relative;
    display: inline;
    width: 500px;
    height: 40px;
    background: #444;
    background: rgba(0, 0, 0, .2);
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.20);
    margin-left: 5%;
    margin-top: 100%;
}

.search-form input,
select {
    width: 470px;
    color: rgb(0, 0, 0);
    background: transparent;
    border-radius: 3px 0 0 3px;
    height: 40px;
    border: 1px solid #fff;
}

.search-form option {
    background: rgba(9, 9, 95, 0.652);
    color: rgb(0, 0, 0);
    background: transparent;

}

.search-form button {
    position: relative;
    padding: 0;
    cursor: pointer;
    height: 40px;
    width: 120px;
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
}


